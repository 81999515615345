import { Component, ViewEncapsulation } from '@angular/core';

// notice that foo is inited in index.html
declare var fullName: String;
declare var email: String;

@Component({
  selector: 'app-dialognavbar',
  templateUrl: './dialognavbar.component.html',
  styleUrls: ['./dialognavbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialognavbarComponent {  
  public profileData = {
    displayName: fullName,
    email: email
  };

}