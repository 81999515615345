import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'daisy-intro',
  templateUrl: './daisy-intro.component.html',
  styleUrls: ['./daisy-intro.component.scss']
})
export class DaisyIntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
