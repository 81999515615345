import { DialognavbarComponent } from './dialognavbar/dialognavbar.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { OneIconModule } from '@one/angular-kit/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OneFormFieldModule } from '@one/angular-kit/form';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OneBadgeModule } from '@one/angular-kit/indicator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import {OneFooterModule} from '@one/angular-kit/navigation';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OneTableModule } from '@one/angular-kit/data-table';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { DaisyIntroComponent } from './daisy-intro/daisy-intro.component';

@NgModule({
  declarations: [
    AppComponent,
    DialognavbarComponent,
    DaisyIntroComponent,
    ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OneIconModule,
    OneTopbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    OneBadgeModule,
    MatTabsModule,
    MatDividerModule,
    OneFooterModule,
    OneFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    OneTableModule,
    MatTableModule,
    CdkTableModule,
    MatCheckboxModule,
    MatSortModule,
    DragDropModule,
    MatDialogModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
