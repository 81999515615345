
<mat-toolbar oneTopbar one-double-area-toolbar>
  <mat-toolbar-row one-double-area-row>
    <a href='https://dialogportal.roche.com/dialog/'>Roche Diagnostics</a>
    <a href='https://dialogportal.roche.com/dialog/'>All eServices</a>
    <span class="example-spacer"></span>
    <button [matMenuTriggerFor]="profileMenu" mat-flat-button one-topbar-profile-button>
      <mat-icon>user</mat-icon>
      <span><a></a>{{ profileData.displayName }}</span>
    </button>
    <mat-menu #profileMenu="matMenu" xPosition="before">
      <div class="profile-menu-topbar-example">
        <div class="info-profile-menu-topbar-example">
          <div class="display-name-profile-menu-topbar-example">{{profileData.displayName}}</div>
          <span class="mat-caption">{{profileData.email}}</span>
        </div>
      </div>
    </mat-menu>
    <mat-divider [vertical]="true" class="one-vertical-divider"></mat-divider>
    <button [matMenuTriggerFor]="languageMenu" mat-flat-button one-topbar-profile-button>
      <mat-icon>globe</mat-icon>
      <span><a></a>EN</span>
    </button>
    <mat-menu #languageMenu="matMenu" xPosition="before">
      <button mat-menu-item>
        <mat-icon id="yesIcon">yes</mat-icon>
        <span>English</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
  
  <mat-toolbar-row>
    <img class="icon-placeholder" alt="Icon placeholder" src="assets/favicon.ico" />
    <span one-topbar-title>Daisy Chatbot</span>
    <span class="example-spacer"></span>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54.61 28.35"
      class="roche-logo-example"
      width="58"
      alt="Roche logo"
    >
      <title>Artboard 1</title>
      <path
        class="cls-1"
        d="M36.94,10.88a2.34,2.34,0,0,0-1.4-.4,2.51,2.51,0,0,0-1.49.49V7.39H32.28V19.06h1.77V13a1,1,0,0,1,1-.92,1.09,1.09,0,0,1,.91.42,2.22,2.22,0,0,1,.2,1.24v5.35H38V13.64C38,12,37.74,11.48,36.94,10.88Z"
      />
      <path
        class="cls-1"
        d="M21.42,10.48c-1.65,0-2.65,1-2.83,3a13.66,13.66,0,0,0-.05,1.38c0,.68,0,1.23,0,1.38.12,1.84,1.18,3,2.84,3s2.72-1.16,2.83-3c0-.12.05-.69.05-1.37,0-.31,0-1.14-.05-1.38C24,11,22.58,10.48,21.42,10.48Zm1.07,6c-.06.63-.33,1.31-1.07,1.31s-1-.72-1.07-1.34c0,0,0-.39,0-1.53s0-1.62,0-1.63c0-.64.35-1.29,1.07-1.29s1.06.65,1.07,1.29c0,0,.05.48.05,1.63A14.09,14.09,0,0,1,22.49,16.45Z"
      />
      <path
        class="cls-1"
        d="M44.84,14.37c0-.14,0-.37-.05-.86a3.93,3.93,0,0,0-.76-2.1,2.52,2.52,0,0,0-2-.93c-1.59,0-2.59,1-2.78,3a10.69,10.69,0,0,0,0,1.38c0,.68,0,1.25,0,1.38.11,1.81,1.19,3,2.79,3s2.68-1.14,2.79-3.07h-1.7a1.61,1.61,0,0,1,0,.29c0,.41-.21,1.29-1.08,1.29s-1-.67-1.06-1.33a9.56,9.56,0,0,1,0-1.12h3.94S44.85,14.72,44.84,14.37Zm-3.93-.53c0-.39,0-.47,0-.58,0-.7.36-1.28,1.08-1.29s1.05.83,1.08,1.29c0,.24,0,.4,0,.58Z"
      />
      <path
        class="cls-1"
        d="M42.41,0H12.24L0,14.22,12.24,28.35H42.41l12.2-14.09Zm-.69,27H12.94l-11-12.75,11-12.83H41.72l11,12.88Z"
      />
      <path
        class="cls-1"
        d="M17.31,15.48c-.08-1.29-.42-2-1.08-2.43a2.68,2.68,0,0,0,1.09-2.49,2.8,2.8,0,0,0-3.07-3.08H11.54V19.06h1.75v-5.3h1c.56,0,1.19.5,1.29,1.88l.18,2.71a3.84,3.84,0,0,0,.09.72h1.75a3.72,3.72,0,0,1-.09-.73Zm-1.75-4.62a1.35,1.35,0,0,1-1.34,1.41h-.93V9h.84a1.27,1.27,0,0,1,1.43,1.35c0,.06,0,.14,0,.27S15.56,10.8,15.56,10.86Z"
      />
      <path
        class="cls-1"
        d="M28.33,12c.75,0,1.06.65,1.07,1.29l0,.5h1.75c0-.12,0-.22,0-.27-.19-2.42-1.67-3-2.83-3-1.65,0-2.65,1-2.83,3a13.66,13.66,0,0,0-.05,1.38c0,.68,0,1.23,0,1.38.12,1.84,1.18,3,2.84,3s2.67-1,2.83-3a.46.46,0,0,0,0-.16H29.42c0,.13,0,.24,0,.32,0,.65-.28,1.34-1.07,1.34s-1-.75-1.08-1.3c0-.33,0-.59,0-1.57,0-1.15,0-1.44,0-1.63C27.33,12.54,27.61,12,28.33,12Z"
      />
    </svg>
  </mat-toolbar-row>
</mat-toolbar>

