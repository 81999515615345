<div id="daisy" style=" padding: 40px;padding-left: 0;">
    <img src="assets/daisy.png" height="300px" style="float: left; margin: 20px">
    <p class="mat-h3">
         "Hi I am Daisy, Roche Digital Assistant."
    </p>
    <p class="mat-h3">
         "Are you looking for information from instructions for use (IFU, method sheets, pack inserts) from clinical chemistry (c 111, c 311 etc.) and Immunochemistry analyzers (e 411, e 801 etc.)"
    </p>
    <p class="mat-h3">
         "I can answer your queries related to assays, calibrators or quality controls with just a few clicks."
    </p>
  </div>